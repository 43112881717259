import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Card, Col, Container, Row } from "react-bootstrap";

export const ReleasePageTemplate = ({ title, directContact, products, language }) => {
  return (
    <Container className="mt-5">
      <h2>{title}</h2>
      <Row style={{justifyContent: 'center'}} className="w-full  justify-center items-center">
       <iframe width="100%" height="400" style={{maxWidth: 956}} src="https://letscast.fm/podcasts/mindful-mozart-podcast-a0b6d027/iframe-player?size=l" frameborder="0" scrolling="no" tabindex="0"></iframe>
      </Row>
      <Row className="mt-5" style={{ justifyContent: "center" }}>
        { products.map(product => {

        const imgSrc = product.image ? product.image.childImageSharp.fluid.src : "";
        return <Col key={product.title} md="5" className="mb-5">
          <Card>
            <Card.Img variant="top" src={imgSrc} />

            <Card.Body>
            <Card.Title>
              {product.title}
            </Card.Title>
              <Card.Text>
                { product.description }
              </Card.Text>
              { product.spotify && <Card.Link target="_blank" href={product.spotify}><i className="fa fa-spotify"></i> Spotify</Card.Link> }
              { product.itunes && <Card.Link target="_blank" href={product.itunes}><i className="fa fa-apple"></i> Apple Music</Card.Link> }
              { product.directSell && <Card.Link target="_blank" href={"mailto:" + directContact}><i className="fa fa-envelope"></i> { {'en': "On request", "de": "Auf Anfrage"}[language] }</Card.Link> }
            </Card.Body>
          </Card>
        </Col>})}

      </Row>
    </Container>
  );
};

ReleasePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  directContact: PropTypes.string.isRequired,
  products: PropTypes.array,
  language: PropTypes.string
};

const ReleasePage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout path={post.frontmatter.path} language={post.frontmatter.language}>
      <ReleasePageTemplate
        products={post.frontmatter.products}
        directContact={post.frontmatter.directContact}
        title={post.frontmatter.title}
        language={post.frontmatter.language}
      />
    </Layout>
  );
};

ReleasePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReleasePage;

export const releasePageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        directContact
        language,
        path,
        products {
          title

          description
          directSell
          spotify
          itunes
          image {
            childImageSharp {
              fluid(maxWidth: 420, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
